import { RuleRowType } from "../components/QueryBuilder/RuleRow";
import { blankRow } from "../components/QueryBuilder/RuleGroup";
import {EntitiesEnum, RuleCondition, CustomField, getCustomFieldsByEntity, CustomFieldType} from "./queryBuilder"

const wineTypeArray = ["White Wine", "Red Wine", "Sparkling", "Rose", "Dessert/Fortified", "Other"]
const compareWineTypeArray = [
  ["white"],
  ["red"],
  ["sparkling"],
  ["rose"],
  ["dessert", "fortified", "sweet", "dessert/fortified"],
  ["other", "different"]
]
const groupNameArray = ["Test Group", "Ned's A List Group", "It Works!", "Zombies!"]
const compareGroupNameArray = [
  ["test", "tests", "testing"],
  ["ned", "ned list", "ned lists", "ned a list", "ned's", "ned's list", "ned's lists", "ned's a list"],
  ["it work", "it works", "it work!", "it works!"],
  ["zombies", "zombies!"]
]
const clubNameArray = ["Club Testing 1,2,3", "Rollin' in Rosé", "Breakfast Club", "Testing 123 Club", "Triple Decker Club", "Zach's Cool Club", "testing buttons club"]
const compareClubNameArray = [
  ["club test", "club tests", "club testing", "club test 123", "club tests 123", "club testing 123", "club test 1,2,3", "club tests 1,2,3", "club testing 1,2,3"],
  ["rollin' in rosé", "rollin in rosé", "rollin' in rose", "rollin in rose"],
  ["breakfast"],
  ["test", "tests", "testing", "test 123", "tests 123", "testing 123", "test 1,2,3", "tests 1,2,3", "testing 1,2,3"],
  ["triple decker"],
  ["zach cool", "zach's cool"],
  ["test button", "test buttons", "tests button", "tests buttons", "testing button", "testing buttons"]
]

export interface Field {
  type: string;
  entity: string;
  key: string;
  options: string[];
  id: string;
  value1: string;
  value2: string;
}

export interface Expression {
  operator: string;
  operands?: Expression[];
  condition: string;
  field?: Field;
  id: string;
  value1: string;
  value2: string;
}

export interface QueryResult {
  expression: Expression | null;
  error_message: string | null;
}

export interface QueryData {
  id: string;
  entity_type: EntitiesEnum;
  model?: string | null;
  query_text: string;
  result_text?: string | null;
  corrected_text: string | null;
  is_pass: number | null
  account_id?: string;
}

export async function getTestQueries(
  account_id?: string
): Promise<QueryData[]> {
  const queryResponse = await fetch(`/api/v1/query/get-test-queries/${account_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!queryResponse.ok) {
    throw new Error("Get test queries failed");
  }

  const queries = (await queryResponse.json()) as QueryData[];
  queries.forEach((q) => {
    if(q.result_text){
      var queryResult: QueryResult
      if(q.result_text.includes("\"expression\"") || q.result_text.includes("\"error_message\"")){
        queryResult = JSON.parse(q.result_text) as QueryResult;
      }else{
        queryResult = {} as QueryResult
        queryResult.expression = JSON.parse(q.result_text) as Expression;
      }
      var result = dealWithQueryResult(queryResult);
      if(result.length > 0){
        q.result_text = result;
        var pass = 0
        q.corrected_text?.split(";").forEach((e) => {
          if(result === e){
            pass = 1
          }
        })
        q.is_pass = pass
      }else{
        q.is_pass = 0
      }
    }
  })
  return queries;
}

export async function doNLEvaluate(
  queryId: string,
  score: number
) {
  const queryResponse = await fetch(`/api/v1/query/${queryId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      evaluate: score,
      corrected_text: "",
      is_pass: -1,
    }),
  });

  if (!queryResponse.ok) {
    throw new Error("Do Natural Language Query Evaluate failed");
  }
}

export async function doNLFeedback(
  queryId: string,
  feedback: string
) {
  const queryResponse = await fetch(`/api/v1/query/${queryId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      evaluate: 0,
      corrected_text: feedback,
      is_pass: -1,
    }),
  });

  if (!queryResponse.ok) {
    throw new Error("Do Natural Language Query Evaluate failed");
  }
}

export async function query(
  entity_type: string,
  content: string,
  model: string,
  show_question: boolean,
  query_id: string,
  account_id?: string
): Promise<QueryData> {
  const queryResponse = await fetch(`/api/v1/query/query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      entity_type: entity_type,
      content: content,
      model: model,
      show_question: "false",
      // show_question: show_question ? "true" : "false"
      query_id: query_id.toString(),
      account_id: account_id
    }),
  });

  if (!queryResponse.ok) {
    throw new Error("Natural Language Query failed");
  }

  const queryData = (await queryResponse.json()) as QueryData;

  return queryData;
}

export function getRuleGroupValueFromNL(expression: Expression, limitEntities: string[]): RuleRowType[][]{
  var groupArray: RuleRowType[][] = []

  if(expression.operator){
    switch(expression.operator){
      case 'or':
        groupArray.push([])
        expression.operands?.forEach((e) => {
          if(e.operator){
            switch(e.operator){
              case 'or':
                e.operands?.forEach((e1) =>{
                  if(e1.condition){
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      groupArray.forEach((g) => {
                        g.push(item)
                      })
                    }
                  }
                })
                break;
              case 'and':
                var newGroupArray: RuleRowType[][] = []
                e.operands?.forEach((e1) =>{
                  if(e1.condition){
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      groupArray.forEach((g) => {
                        var ng: RuleRowType[] = []
                        g.forEach((g1) => {
                          ng.push(g1)
                        })
                        ng.push(item)
                        newGroupArray.push(ng)
                      })
                    }
                  }
                })
                groupArray = newGroupArray
                break;
              case 'not':
                if(e.operands && e.operands?.length > 0){
                  var e1 = e.operands[0]
                  if(e1.condition){
                    e1.condition = getReverseConditionStr(e1.condition)
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      groupArray.forEach((g) => {
                        g.push(item)
                      })
                    }
                  }
                }
                break;
            }
          }else if(e.condition){
            const item = getRuleRowValueFromNL(e, limitEntities)
            if(item){
              groupArray.forEach((g) => {
                g.push(item)
              })
            }
          }
        })
        break;
      case 'and':
        expression.operands?.forEach((e) => {
          if(e.operator){
            var rowArray: RuleRowType[] = []
            e.operands?.forEach((e1) =>{
              if(e1.condition){
                switch(e.operator){
                  case 'or':{
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      rowArray.push(item)
                    }
                    break;
                  }
                  case 'and':{
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      groupArray.push([item])
                    }
                    break;
                  }
                  case 'not':{
                    e1.condition = getReverseConditionStr(e1.condition)
                    const item = getRuleRowValueFromNL(e1, limitEntities)
                    if(item){
                      groupArray.push([item])
                    }
                    break;
                  }
                }
              }
            })
            if(rowArray.length > 0){
              groupArray.push(rowArray)
            }
          }else if(e.condition){
            const item = getRuleRowValueFromNL(e, limitEntities)
            if(item){
              groupArray.push([item])
            }
          }
        })
        break;
      case 'not':
        if(expression.operands && expression.operands?.length > 0){
          var e = expression.operands[0]
          if(e.condition){
            e.condition = getReverseConditionStr(e.condition)
            const item = getRuleRowValueFromNL(e, limitEntities)
            if(item){
              groupArray.push([item])
            }
          }
        }
        break;
    }
  }else if(expression.condition){
    const item = getRuleRowValueFromNL(expression, limitEntities)
    if(item){
      groupArray.push([item])
    }
  }

  if(groupArray.length === 1 && groupArray[0].length === 0){
    groupArray.splice(0, 1)
  }
  if(groupArray.length === 0){
    groupArray.push([blankRow])
  }else{
    groupArray = doCompareGroup(groupArray)
  }
  return groupArray
}

function doMultiCompare(condition: RuleCondition, compareArray: RuleRowType[], r: RuleRowType){
  if(compareArray.length > 0){
    var compareResult = false
    compareArray.forEach((c) => {
      if(c.field?.entity === r.field?.entity && c.field?.key === r.field?.key && c.condition === condition){
        c.value1 += "|" + r.value1
        compareResult = true
      }
    })
    if(!compareResult){
      r.condition = condition
      compareArray.push(r)
    }
  }else{
    r.condition = condition
    compareArray.push(r)
  }
}

function getReverseConditionStr(condition: string){
  var nonCondition = condition;
  switch(condition){
    case RuleCondition.MATCHES_EXACTLY:
      nonCondition = RuleCondition.DOES_NOT_MATCH_EXACTLY;
      break;
    case RuleCondition.IS_PROVIDED:
      nonCondition = RuleCondition.IS_NOT_PROVIDED;
      break;
    case RuleCondition.CONTAINS:
      nonCondition = RuleCondition.DOES_NOT_CONTAIN;
      break;
    case RuleCondition.STARTS_WITH:
      nonCondition = RuleCondition.DOES_NOT_START_WITH;
      break;
    case RuleCondition.ENDS_WITH:
      nonCondition = RuleCondition.DOES_NOT_END_WITH;
      break;
    case RuleCondition.EQUALS:
      nonCondition = RuleCondition.DOES_NOT_EQUAL;
      break;
    case RuleCondition.IN:
      nonCondition = RuleCondition.NOT_IN;
      break;
    case RuleCondition.DOES_NOT_MATCH_EXACTLY:
      nonCondition = RuleCondition.MATCHES_EXACTLY;
      break;
    case RuleCondition.IS_NOT_PROVIDED:
      nonCondition = RuleCondition.IS_PROVIDED;
      break;
    case RuleCondition.DOES_NOT_CONTAIN:
      nonCondition = RuleCondition.CONTAINS;
      break;
    case RuleCondition.DOES_NOT_START_WITH:
      nonCondition = RuleCondition.STARTS_WITH;
      break;
    case RuleCondition.DOES_NOT_END_WITH:
      nonCondition = RuleCondition.ENDS_WITH;
      break;
    case RuleCondition.DOES_NOT_EQUAL:
      nonCondition = RuleCondition.EQUALS;
      break;
    case RuleCondition.NOT_IN:
      nonCondition = RuleCondition.IN;
      break;
  }
  return nonCondition;
}

function getReverseCondition(condition: RuleCondition){
  var nonCondition = condition;
  switch(condition){
    case RuleCondition.MATCHES_EXACTLY:
      nonCondition = RuleCondition.DOES_NOT_MATCH_EXACTLY;
      break;
    case RuleCondition.IS_PROVIDED:
      nonCondition = RuleCondition.IS_NOT_PROVIDED;
      break;
    case RuleCondition.CONTAINS:
      nonCondition = RuleCondition.DOES_NOT_CONTAIN;
      break;
    case RuleCondition.STARTS_WITH:
      nonCondition = RuleCondition.DOES_NOT_START_WITH;
      break;
    case RuleCondition.ENDS_WITH:
      nonCondition = RuleCondition.DOES_NOT_END_WITH;
      break;
    case RuleCondition.EQUALS:
      nonCondition = RuleCondition.DOES_NOT_EQUAL;
      break;
    case RuleCondition.IN:
      nonCondition = RuleCondition.NOT_IN;
      break;
    case RuleCondition.DOES_NOT_MATCH_EXACTLY:
      nonCondition = RuleCondition.MATCHES_EXACTLY;
      break;
    case RuleCondition.IS_NOT_PROVIDED:
      nonCondition = RuleCondition.IS_PROVIDED;
      break;
    case RuleCondition.DOES_NOT_CONTAIN:
      nonCondition = RuleCondition.CONTAINS;
      break;
    case RuleCondition.DOES_NOT_START_WITH:
      nonCondition = RuleCondition.STARTS_WITH;
      break;
    case RuleCondition.DOES_NOT_END_WITH:
      nonCondition = RuleCondition.ENDS_WITH;
      break;
    case RuleCondition.DOES_NOT_EQUAL:
      nonCondition = RuleCondition.EQUALS;
      break;
    case RuleCondition.NOT_IN:
      nonCondition = RuleCondition.IN;
      break;
  }
  return nonCondition;
}

function getRuleRowValueFromNL(operand: Expression, limitEntities: string[]): RuleRowType | null{
  if(operand.field && limitEntities.includes(operand.field?.entity)){
    let entity : EntitiesEnum | null = null
    let fieldKey = operand.field?.key
    if(fieldKey === "wine_type"){
      entity = EntitiesEnum.PRODUCTS
    }else if(fieldKey === "customer_id"){
      entity = EntitiesEnum.CUSTOMERS
      fieldKey = "remote_id"
    }else if(fieldKey === "group_id"){
      entity = EntitiesEnum.GROUPS
      fieldKey = "remote_id"
    }else if(fieldKey === "product_id"){
      entity = EntitiesEnum.PRODUCTS
      fieldKey = "remote_id"
    }else{
      Object.values(EntitiesEnum).forEach((e) => {
        if(e === operand.field?.entity){
          entity = e;
        }
      })
    }
    if(fieldKey === "id"){
      fieldKey = "remote_id"
    }

    if(entity){
      let customFields = getCustomFieldsByEntity(entity)
      let customField: CustomField | null = null
      customFields.forEach((field) => {
        if(field.key === fieldKey){
          customField = field
        }
      })
      if(customField){
        let condition : RuleCondition | null = null
        Object.values(RuleCondition).forEach((e) => {
          if(e === operand.condition){
            if(e === RuleCondition.IS_BETWEEN && operand.value1 === operand.value2){
              if(customField?.type === CustomFieldType.NUMBER){
                condition = RuleCondition.MATCHES_EXACTLY
              }else{
                condition = RuleCondition.EQUALS
              }
            }else{
              condition = e;
            }
          }
        })
        var value1 = operand.value1? operand.value1 : operand.field.value1;
        var value2 = operand.value2? operand.value2 : operand.field.value2;
        if(!value1 && value2){
          value1 = value2;
        }
        return {
          field: customField,
          condition: condition ? condition : "",
          value1: value1,
          value2: value2,
        };
      }
    }
  }
  return null
}

function getLowerCase(value: string | undefined): string{
  var result = "";
  if(value){
    result = value.toString().toLowerCase();
  }
  return result;
}

function doCompareGroup(groupArray: RuleRowType[][]): RuleRowType[][]{
  var compareGroup: RuleRowType[][] = []
    groupArray.forEach((g, gIndex) => {
      var compareArray: RuleRowType[] = []
      var needAdd = true
      g.forEach((r, rIndex) =>{
        var tempValue = getLowerCase(r.value1).replaceAll(" ", "");
        if((r.field?.key === "clubs" && tempValue != "whitewine") ||
          (r.field?.entity === EntitiesEnum.PRODUCTS && r.field.key === "name") ||
          r.field?.key === "wine_type"){
          compareWineTypeArray.forEach((value, index) => {
            value.forEach((e) => {
              var compareValue = getLowerCase(e).replaceAll(" ", "");
              if(tempValue === compareValue || tempValue === compareValue + "wine" || tempValue === compareValue + "wines"){
                var field = getCustomFieldsByEntity(EntitiesEnum.PRODUCTS).find((customField) => customField.key === "wine_type");
                if(field){
                  r.field = field
                }
                r.value1 = wineTypeArray[index]
                if(r.condition === RuleCondition.IS_PROVIDED || r.condition === RuleCondition.CONTAINS){
                  r.condition = RuleCondition.MATCHES_EXACTLY
                }else if(r.condition === RuleCondition.IS_NOT_PROVIDED || r.condition === RuleCondition.DOES_NOT_CONTAIN){
                  r.condition = RuleCondition.DOES_NOT_MATCH_EXACTLY
                }
              }
            })
          })
        }
        if((r.field?.entity === EntitiesEnum.GROUPS && r.field.key === "name") ||
          (r.field?.entity === EntitiesEnum.CUSTOMERS && r.field.key === "groups")){
          var compared = false
          compareGroupNameArray.forEach((value, index) => {
            value.forEach((e) => {
              var compareValue = getLowerCase(e).replaceAll(" ", "");
              if(tempValue === compareValue || tempValue === compareValue + "group" || tempValue === compareValue + "groups"){
                compared = true;
                r.value1 = groupNameArray[index]
                if(r.condition === RuleCondition.IS_PROVIDED || r.condition === RuleCondition.CONTAINS){
                  r.condition = RuleCondition.MATCHES_EXACTLY
                }else if(r.condition === RuleCondition.IS_NOT_PROVIDED || r.condition === RuleCondition.DOES_NOT_CONTAIN){
                  r.condition = RuleCondition.DOES_NOT_MATCH_EXACTLY
                }
              }
            })
          })
          if(!compared){
            if(getLowerCase(r.value1).endsWith(" group")){
              r.value1 = r.value1.substring(0, r.value1.length - 6);
            }else if(getLowerCase(r.value1).endsWith(" groups")){
              r.value1 = r.value1.substring(0, r.value1.length - 7);
            }
          }
        }
        if(r.field?.entity === EntitiesEnum.CUSTOMERS && r.field.key === "clubs"){
          var compared = false
          compareClubNameArray.forEach((value, index) => {
            value.forEach((e) => {
              var compareValue = getLowerCase(e).replaceAll(" ", "");
              if(tempValue === compareValue || tempValue === compareValue + "club" || tempValue === compareValue + "clubs"){
                compared = true;
                r.value1 = clubNameArray[index]
                if(r.condition === RuleCondition.IS_PROVIDED || r.condition === RuleCondition.CONTAINS){
                  r.condition = RuleCondition.MATCHES_EXACTLY
                }else if(r.condition === RuleCondition.IS_NOT_PROVIDED || r.condition === RuleCondition.DOES_NOT_CONTAIN){
                  r.condition = RuleCondition.DOES_NOT_MATCH_EXACTLY
                }
              }
            })
          })
          if(!compared){
            if(getLowerCase(r.value1).endsWith(" club")){
              r.value1 = r.value1.substring(0, r.value1.length - 5);
            }else if(getLowerCase(r.value1).endsWith(" clubs")){
              r.value1 = r.value1.substring(0, r.value1.length - 6);
            }
          }
        }
        if(r.field?.key === "status"){
          if(tempValue === "active" || tempValue === "available" || tempValue === "true"){
            r.value1 = "Active"
          }else if(tempValue === "inactive" || tempValue === "unavailable" || tempValue === "false"){
            r.value1 = "Inactive"
          }
        }
        if(r.field?.type === CustomFieldType.OPTIONS_MULTIPLE){
          if(r.condition === RuleCondition.MATCHES_EXACTLY || r.condition === RuleCondition.CONTAINS || r.condition === RuleCondition.EQUALS){
            doMultiCompare(RuleCondition.IN, compareArray, r)
          }else if(r.condition === RuleCondition.DOES_NOT_MATCH_EXACTLY || r.condition === RuleCondition.DOES_NOT_CONTAIN || r.condition === RuleCondition.DOES_NOT_EQUAL){
            doMultiCompare(RuleCondition.NOT_IN, compareArray, r)
          }else{
            compareArray.push(r)
          }
        }else{
          if(g.length === 1 && r.field?.type === CustomFieldType.NUMBER){
            if(r.condition === RuleCondition.LESS_THAN ||
              r.condition === RuleCondition.LESS_THAN_OR_EQUAL_TO ||
              r.condition === RuleCondition.GREATER_THAN ||
              r.condition === RuleCondition.GREATER_THAN_OR_EQUAL_TO){
                if(gIndex < groupArray.length - 1 && groupArray[gIndex + 1].length === 1){
                  var nextRow = groupArray[gIndex + 1][0]
                  if(nextRow.field?.type === CustomFieldType.NUMBER && 
                    r.field.entity === nextRow.field.entity && 
                    r.field.key === nextRow.field.key){
                    if((r.condition === RuleCondition.LESS_THAN ||
                      r.condition === RuleCondition.LESS_THAN_OR_EQUAL_TO) &&
                      (nextRow.condition === RuleCondition.GREATER_THAN ||
                      nextRow.condition === RuleCondition.GREATER_THAN_OR_EQUAL_TO)){
                      needAdd = false
                      nextRow.condition = RuleCondition.IS_BETWEEN
                      nextRow.value2 = r.value1
                    }else if((r.condition === RuleCondition.GREATER_THAN ||
                      r.condition === RuleCondition.GREATER_THAN_OR_EQUAL_TO) &&
                      (nextRow.condition === RuleCondition.LESS_THAN ||
                      nextRow.condition === RuleCondition.LESS_THAN_OR_EQUAL_TO)){
                        needAdd = false
                        nextRow.condition = RuleCondition.IS_BETWEEN
                        nextRow.value2 = nextRow.value1
                        nextRow.value1 = r.value1
                    }
                  }
                }
              }
          }
          if(needAdd){
            compareArray.push(r)
          }
        }
      })
      if(needAdd){
        compareGroup.push(compareArray)
      }
    })

    return compareGroup
}

export function dealWithQueryResult(queryResult: QueryResult): string{
  var result = ""
  if(queryResult.expression){
    result = getQueryLogicCode(getRuleGroupValueFromNL(
      queryResult.expression, 
      [
        EntitiesEnum.GROUPS, 
        EntitiesEnum.CUSTOMERS,
        EntitiesEnum.ORDERS,
        EntitiesEnum.PRODUCTS,
    ]))
  }
  return result;
}

export function getQueryLogicCode(ruleGroups: RuleRowType[][]): string{
  var result = ""
  ruleGroups.forEach((g) =>{
    if(result.length > 0){
      result += " && "
    }
    if(g.length > 1){
      result += "("
    }
    g.forEach((r, i) =>{
      if(i > 0){
        result += " || "
      }
      result += r.field?.entity + "." + r.field?.key
      switch(r.condition){
        case RuleCondition.MATCHES_EXACTLY:
        case RuleCondition.EQUALS:
        case RuleCondition.IN:
          if(r.field?.type === CustomFieldType.NUMBER){
            result += " == " + r.value1
          }else{
            result += " == \"" + r.value1 + "\""
          }
          break;
        case RuleCondition.DOES_NOT_MATCH_EXACTLY:
        case RuleCondition.DOES_NOT_EQUAL:
        case RuleCondition.NOT_IN:
          if(r.field?.type === CustomFieldType.NUMBER){
            result += " != " + r.value1
          }else{
            result += " != \"" + r.value1 + "\""
          }
          break;
        case RuleCondition.IS_PROVIDED:
          result += " != null"
          break;
        case RuleCondition.IS_NOT_PROVIDED:
          result += " == null"
          break;
        case RuleCondition.CONTAINS:
          result += " contains(\"" + r.value1 + "\")"
          break;
        case RuleCondition.DOES_NOT_CONTAIN:
          result += " not contains(\"" + r.value1 + "\")"
          break;
        case RuleCondition.STARTS_WITH:
          result += " startsWith(\"" + r.value1 + "\")"
          break;
        case RuleCondition.DOES_NOT_START_WITH:
          result += " not startsWith(\"" + r.value1 + "\")"
          break;
        case RuleCondition.ENDS_WITH:
          result += " endsWith(\"" + r.value1 + "\")"
          break;
        case RuleCondition.DOES_NOT_END_WITH:
          result += " not endsWith(\"" + r.value1 + "\")"
          break;
        case RuleCondition.GREATER_THAN:
          result += " > " + r.value1
          break;
        case RuleCondition.LESS_THAN:
          result += " < " + r.value1
          break;
        case RuleCondition.GREATER_THAN_OR_EQUAL_TO:
          result += " >= " + r.value1
          break;
        case RuleCondition.LESS_THAN_OR_EQUAL_TO:
          result += " <= " + r.value1
          break;
        case RuleCondition.IS_AFTER:
          result += " > \"" + r.value1 + "\""
          break;
        case RuleCondition.IS_BEFORE:
          result += " < \"" + r.value1 + "\""
          break;
        case RuleCondition.IS_ON_OR_AFTER:
          result += " >= \"" + r.value1 + "\""
          break;
        case RuleCondition.IS_ON_OR_BEFORE:
          result += " <= \"" + r.value1 + "\""
          break;
        case RuleCondition.IS_BETWEEN:
          if(r.field?.type === CustomFieldType.NUMBER){
            result += " between(" + r.value1 + ", " + r.value2 + ")"
          }else{
            result += " between(\"" + r.value1 + "\", \"" + r.value2 + "\")"
          }
          break;
      }
    })
    if(g.length > 1){
      result += ")"
    }
  })

  return result;
}