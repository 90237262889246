import React, { useState } from "react";
import {
  Flex,
  Image,
  Link,
  Text,
  Select,
} from "@chakra-ui/react";
import { useToken, UserInfo, getUserTokenByAccount } from "../../services/auth";
import { useHistory, Link as RouterDomLink } from "react-router-dom";
import { EntitiesEnum } from "../../services/queryBuilder";
import {
  PAGE_INDEX_SEGMENTS, 
  PAGE_INDEX_TESTS,
} from "../../utils/constants";

type SideBarProps = {
  pageIndex: number;
  setToken: (userInfo: UserInfo | null) => void;
};

const SideBar = ({pageIndex, setToken} : SideBarProps) => {
  const { userInfo } = useToken();
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInfo | null>(userInfo);
  const history = useHistory();

  const handleChange = async (id: string) => {
    if (currentUserInfo && currentUserInfo.accounts) {
      var currentUserAccount = currentUserInfo.currentAccount;
      currentUserInfo.accounts.forEach((e) =>{
        if(e.id == id){
          currentUserAccount = e;
        }
      })
      if(currentUserAccount){
        const token = await getUserTokenByAccount(
          currentUserInfo.ticket,
          currentUserAccount.account
        );

        const newUserInfo: UserInfo = {
          ...currentUserInfo,
          currentAccount: currentUserAccount,
          token,
        };
        setToken(newUserInfo);
        setCurrentUserInfo(newUserInfo);

        history.push(`/segs/customer`);
        window.location.reload();
      }
    }
  };

  return (
    <Flex flexDir={"column"} width={"260px"}>
      {currentUserInfo?.accounts && currentUserInfo?.currentAccount && (
        <Flex
          pt={"12px"}
          pl={"24px"}
          alignItems={"center"}
        >
          <Select
            width={"160px"}
            backgroundColor={"#505268"}
            borderColor={"#505268"}
            color={"#DDE0FB"}
            variant="offset"
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Beatrice-Regular"}
            pl={"3px"}
            cursor={"pointer"}
            onChange={(e) => handleChange(e.target.value)}
            value={currentUserInfo.currentAccount.id}
          >
            {Object.values(currentUserInfo?.accounts).map((a) => (
              <option key={a.id} value={a.id}>
                {a.name}
              </option>
            ))}
          </Select>
          <Image 
            src="/menu_dot.png" 
            w={"16px"} 
            h={"16px"} 
            mb={"3px"}
            position={"absolute"}
          />
        </Flex>
        )}
      <Link
        ml={"24px"}
        mt={"54px"}
        color={pageIndex == PAGE_INDEX_SEGMENTS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice-Regular"}
        _focus={{ boxShadow: "none" }}
        textDecor={pageIndex == PAGE_INDEX_SEGMENTS ? "underline" : ""}
        textAlign={"left"}
        as={RouterDomLink}
        to={`/segs/${EntitiesEnum.CUSTOMERS}`}
      >
        Segments
      </Link>
      {/* <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_ANALYTICS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice-Regular"}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_ANALYTICS ? "underline" : ""}
        textAlign={"left"}
      >
        Analytics
      </Link>
      <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_DASHBOARDS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice-Regular"}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_DASHBOARDS ? "underline" : ""}
        textAlign={"left"}
      >
        Dashboards
      </Link>
      <Link
        ml={"24px"}
        mt={"12px"}
        color={selPageIndex == PAGE_INDEX_SYNC ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice-Regular"}
        _focus={{ boxShadow: "none" }}
        textDecor={selPageIndex == PAGE_INDEX_SYNC ? "underline" : ""}
        textAlign={"left"}
      >
        Sync
      </Link> */}
      <Link
        ml={"24px"}
        mt={"12px"}
        color={pageIndex == PAGE_INDEX_TESTS ? "white" : "#DDE0FB"}
        fontSize={20}
        fontFamily={"Beatrice-Regular"}
        _focus={{ boxShadow: "none" }}
        textDecor={pageIndex == PAGE_INDEX_TESTS ? "underline" : ""}
        textAlign={"left"}
        as={RouterDomLink}
        to={`/tests/${EntitiesEnum.CUSTOMERS}`}
      >
        Tests
      </Link>
      <Flex 
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"end"}
        ml={"24px"}
        mb={"32px"}
        h={"100%"}
      >
        <Image 
          src="/offset-logo-white.png" 
          w={"80px"} />
        <Text
          mt={3}
          fontSize={"11px"} 
          color={"#dde0fb"}>
            © 2023
        </Text>
        <Link
          mt={0.5} 
          fontSize={"11px"} 
          color={"#dde0fb"}
          _focus={{ boxShadow: "none" }}
          href={"https://www.offsetpartners.com/terms/"}
          target={"_blank"}
        >
            Terms & Conditions
        </Link>
        <Link
          mt={0.5}
          fontSize={"11px"} 
          color={"#dde0fb"}
          _focus={{ boxShadow: "none" }}
          href={"https://www.offsetpartners.com/privacy/"}
          target={"_blank"}
        >
            Privacy Policy
        </Link>
        <Link
          mt={0.5}
          fontSize={"11px"} 
          color={"#dde0fb"}
          _focus={{ boxShadow: "none" }}
          href={"mailto:support@offsetpartners.com"}
          target={"_blank"}
        >
            Support
        </Link>
      </Flex>
    </Flex>
  );
};

export default SideBar;
