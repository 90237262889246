import React, {useState} from "react";
import { 
  Flex,
  useColorModeValue,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import SegmentList from "./pages/SegmentList/SegmentList";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import { useToken } from "./services/auth";
import SideBar from "./components/SideBar/SideBar";
import CustomerSegment from "./pages/Segment/CustomerSegment";
import ProductSegment from "./pages/Segment/ProductSegment";
import OrderSegment from "./pages/Segment/OrderSegment";
import { EntitiesEnum } from "./services/queryBuilder";
import TestConsole from "./pages/Test/TestConsole";
import {
  PAGE_INDEX_SEGMENTS, 
  PAGE_INDEX_TESTS,
} from "./utils/constants";

export const App = () => {
  const { token, setToken } = useToken();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(PAGE_INDEX_SEGMENTS);
  const formBackground = useColorModeValue("#505268", "#505268");

  const handleSignOut = () => {
    setToken(null);
  }

  const handleSetPageIndex = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
  }

  if (!token) {
    return (
      <BrowserRouter>
        <Flex direction={"column"}>
          <Flex height={"93.7vh"} minHeight={"720px"}>
            <Switch>
              <Route path="/forgot">
                <ForgotPassword />
              </Route>
              <Route path="/reset">
                <ResetPassword />
              </Route>
              <Route>
                <Login setToken={setToken} />
              </Route>
            </Switch>
          </Flex>
          <Flex 
            alignItems={"center"}
            minHeight={"60px"}
            px={20}
          >
            <Flex
              minWidth={"160px"}
              flexDirection={"column"}
            >
              <Image 
                src="/offset-logo-white.png" 
                w={"80px"} />
            </Flex>
            <Flex
              minWidth={"220px"}
              width={"100%"}
              justifyContent={"end"}
            >
              <Link
                fontSize={"11px"} 
                color={"#dde0fb"}
                _focus={{ boxShadow: "none" }}
                href={"mailto:support@offsetpartners.com"}
              >
                  support@offsetpartners.com
              </Link>
              <Text
                fontSize={"11px"} 
                color={"#dde0fb"}
                mx={1.5}
              >
                /
              </Text>
              <Link
                fontSize={"11px"} 
                color={"#dde0fb"}
                _focus={{ boxShadow: "none" }}
                href={"https://www.offsetpartners.com/terms/"}
                target={"_blank"}
              >
                  Terms & Conditions
              </Link>
              <Text
                fontSize={"11px"} 
                color={"#dde0fb"}
                mx={1.5}
              >
                /
              </Text>
              <Link
                fontSize={"11px"} 
                color={"#dde0fb"}
                _focus={{ boxShadow: "none" }}
                href={"https://www.offsetpartners.com/privacy/"}
                target={"_blank"}
              >
                  Privacy Policy
              </Link>
              <Text
                fontSize={"11px"} 
                color={"#dde0fb"}
                mx={1.5}
              >
                /
              </Text>
              <Text
                fontSize={"11px"} 
                color={"#dde0fb"}>
                  © 2023
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Flex bg={formBackground} minHeight={"100vh"} textAlign="center" fontSize="xl">
        <SideBar pageIndex={currentPageIndex} setToken={setToken}/>
        <Flex direction={"column"} width={"100%"} pr={"30px"} pb={"24px"}>
          <Switch>
            <Route path="/segs/customer/:id">
              <CustomerSegment signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/segs/customer">
              <SegmentList entityType={EntitiesEnum.CUSTOMERS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/segs/order/:id">
              <OrderSegment signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/segs/order">
              <SegmentList entityType={EntitiesEnum.ORDERS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/segs/product/:id">
              <ProductSegment signOut={handleSignOut} setPageIndex={handleSetPageIndex}/>
            </Route>
            <Route path="/segs/product">
              <SegmentList entityType={EntitiesEnum.PRODUCTS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/tests/customer">
              <TestConsole entityType={EntitiesEnum.CUSTOMERS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/tests/order">
              <TestConsole entityType={EntitiesEnum.ORDERS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/tests/product">
              <TestConsole entityType={EntitiesEnum.PRODUCTS} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Route path="/tests/feedback">
              <TestConsole entityType={null} signOut={handleSignOut} setPageIndex={handleSetPageIndex} />
            </Route>
            <Redirect from="*" to="/segs/customer" />
          </Switch>
        </Flex>
      </Flex>
    </BrowserRouter>
  );
};
