import { getClubs, getGroups } from "./customers";

export enum EntitiesEnum {
  CUSTOMERS = "customer",
  GROUPS = "group",
  PRODUCTS = "product",
  ORDERS = "order",
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionType[];
}

export type OptionType = {
  value: string;
  label: string;
};

export type CustomField = {
  entity: EntitiesEnum;
  key: string;
  type: CustomFieldType;
  asyncOptions?: () => Promise<OptionType[]>;
  label: string;
  options?: OptionType[];
};

export enum CustomFieldType {
  TEXT = "text",
  NUMBER = "number",
  DATE = "date",
  BOOLEAN = "boolean",
  OPTIONS_SINGLE = "options_single",
  OPTIONS_MULTIPLE = "options_multiple",
}

const SALUTATION_OPTIONS: OptionType[] = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Miss", label: "Miss" },
  { value: "Dr.", label: "Dr." },
  { value: "Prof.", label: "Prof." },
  { value: "Rev.", label: "Rev." },
  { value: "Rabbi", label: "Rabbi" },
];

const CUSTOMER_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "remote_id",
    label: "ID",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "active_account",
    label: "Active Account",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "created",
    label: "Signup Date",
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "salutation",
    label: "Salutation",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: SALUTATION_OPTIONS,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "first_name",
    label: "First Name",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "last_name",
    label: "Last Name",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "suffix",
    label: "Suffix",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "nickname",
    label: "Nickname",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "email",
    label: "Email",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "marketing_opt_in",
    label: "Opt In - Email Marketing",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "email_alt",
    label: "Email Alternative",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "first_purchase_date",
    label: "First Purchase Date",
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "last_order",
    label: "Last Order Date",
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "last_order_total",
    label: "Last Order Total",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "order_count",
    label: "Order Count",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "order_total",
    label: "Lifetime Value",
    type: CustomFieldType.NUMBER,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "legacy_order_total",
  //   label: "Legacy Order Total",
  //   type: CustomFieldType.NUMBER,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "score",
    label: "Score",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "clubs",
    label: "Clubs",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const clubs = await getClubs();
      return clubs.map((club) => ({
        value: club.name,
        label: club.name,
      }));
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "groups",
    label: "Groups",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const groups = await getGroups();
      return groups.map((group) => ({
        value: group.name,
        label: group.name,
      }));
    },
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "sub_group",
    label: "Subgroup",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Tier 1", label: "Tier 1" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "customer_type",
    label: "Customer Type",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Allocated", label: "Allocated" },
      { value: "B2B", label: "B2B" },
      { value: "B2C", label: "B2C" },
      { value: "Club", label: "Club" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "customer_source",
    label: "Customer Source",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Web", label: "Web" },
      { value: "POS", label: "POS" },
      { value: "Phone", label: "Phone" },
      { value: "Email", label: "Email" },
      { value: "Mail", label: "Mail" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "website_source",
    label: "Website Source",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "referral_source",
    label: "Referral Source",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Friend", label: "Friend" },
      { value: "Colleague", label: "Colleague" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "referral_note",
    label: "Referral Note",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "referral_code",
    label: "Referral Code",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "vip_pickup",
    label: "VIP Pickup",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "credits",
    label: "Credits",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "birthday",
    label: "Birthday",
    type: CustomFieldType.DATE,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "phone",
    label: "Phone",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mobile_phone",
    label: "Phone - Mobile",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "work",
    label: "Phone - Work",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "phone_call_opt_in",
    label: "Opt In - Phone Call",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "receive_text_messages",
    label: "Opt In - Text",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "salutation_spouse",
    label: "Spouse’s Salutation",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: SALUTATION_OPTIONS,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "first_name_spouse",
    label: "Spouse’s First Name",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "last_name_spouse",
    label: "Spouse’s Last Name",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "suffix_spouse",
    label: "Spouse’s Suffix",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "email_spouse",
    label: "Spouse’s Email",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "spouse_phone",
    label: "Spouse’s Phone",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "children",
    label: "Children",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "pet",
    label: "Pets",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "hobbies",
    label: "Hobbies",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "interests",
    label: "Interests",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "sports",
    label: "Sports",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "job_title",
    label: "Job Title",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "company",
    label: "Company",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "address",
    label: "Address",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "address_2",
    label: "Address 2",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "city",
    label: "City",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "state",
    label: "State",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "zip",
    label: "Zip",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "billing_address",
    label: "Billing Address",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "billing_address_2",
    label: "Billing Address 2",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "billing_city",
    label: "Billing City",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "billing_state",
    label: "Billing State",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "billing_zip",
    label: "Billing Zip",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_company",
    label: "Mailing Company",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_address",
    label: "Mailing Address",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_address_2",
    label: "Mailing Address 2",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_city",
    label: "Mailing City",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_state",
    label: "Mailing State",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_zip",
    label: "Mailing Zip",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mailing_country",
    label: "Mailing Country",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mail_marketing_opt_in",
    label: "Opt In - Postal Mail",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "mail_marketing_opt_in_update",
    label: "Opt In - Postal Mail Update",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "special_shipping",
    label: "Special Shipping",
    type: CustomFieldType.BOOLEAN,
    options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "temporary_customer_notes",
  //   label: "To Do",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_admin_notes",
  //   label: "Admin Notes",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_payment_notes",
  //   label: "Payment Notes",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "alt_shipping_notes",
  //   label: "Shipping Notes",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "comments",
    label: "Comments",
    type: CustomFieldType.TEXT,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "custom_message",
  //   label: "Custom Message",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_ip_address",
    label: "Signup IP Address",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_user_agent",
    label: "Signup User Agents",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36", label: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36" },
      { value: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36", label: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_browser",
    label: "Signup Browser",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Chrome", label: "Chrome" },
      { value: "Edge", label: "Edge" },
      { value: "Firefox", label: "Firefox" },
      { value: "Opera", label: "Opera" },
      { value: "Safari", label: "Safari" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_browser_device_type",
    label: "Signup Browser Device Type",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "Desktop", label: "Desktop" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_browser_platform",
    label: "Signup Browser Platform",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "MacOSX", label: "MacOSX" },
      { value: "Windows", label: "Windows" },
    ],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "signup_browser_version",
    label: "Signup Browser Version",
    type: CustomFieldType.OPTIONS_SINGLE,
    options: [
      { value: "0.0", label: "0.0" },
      { value: "1.0", label: "1.0" },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "sc_order_split",
  //   label: "ShipCompliant Order Split",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "crm_customer_record",
    label: "ID - CRM Customer Record",
    type: CustomFieldType.TEXT,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "legacy_customer_id",
  //   label: "ID - Legacy Customer",
  //   type: CustomFieldType.TEXT,
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "assigned_owner",
    label: "Assigned Owner",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [
      { value: "Bryan Haddock", label: "Bryan Haddock" },
      { value: "Diddly Boo Boo", label: "Diddly Boo Boo" },
      { value: "Jason Lankford", label: "Jason Lankford" },
      { value: "Joseph Shipp", label: "Joseph Shipp" },
      { value: "Ned Creed", label: "Ned Creed" },
      { value: "Some Guy", label: "Some Guy" },
      { value: "That Guy", label: "That Guy" },
      { value: "Tyson Caly", label: "Tyson Caly" },
      { value: "Zachary Stovall", label: "Zachary Stovall" },
    ],
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_date",
  //   label: "Deactivation Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_reason",
  //   label: "Deactivation Reason",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_note",
  //   label: "Deactivation Note",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "deactivation_person",
  //   label: "Deactivation Person",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "reactivation_date",
  //   label: "Reactivation Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "reactivation_note",
  //   label: "Reactivation Note",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "transition_preference",
  //   label: "Transition Preference",
  //   type: CustomFieldType.OPTIONS_SINGLE,
  //   options: [],
  // },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "created_by",
    label: "Account Created By",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [],
  },
  {
    entity: EntitiesEnum.CUSTOMERS,
    key: "last_update",
    label: "Account Last Updated",
    type: CustomFieldType.DATE,
  },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "status",
  //   label: "Status",
  //   type: CustomFieldType.OPTIONS_MULTIPLE,
  //   options: [
  //     { value: "Active", label: "Active" },
  //     { value: "Inactive", label: "Inactive" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "signup_email_last_sent",
  //   label: "Signup Email Last Sent",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "agreed_to_mailing_list",
  //   label: "Agreed to Mailing List",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "agreed_to_terms_privacy",
  //   label: "Agreed to Terms Privacy",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "consent_date",
  //   label: "Consent Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "consent_time",
  //   label: "Consent Time",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "created_date",
  //   label: "Created Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "created_time",
  //   label: "Created Time",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "data_vip_pickup",
  //   label: "Data Vip Pickup",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "facebook",
  //   label: "Facebook",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "inactive_date",
  //   label: "Inactive Date",
  //   type: CustomFieldType.DATE,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "marketing",
  //   label: "Marketing",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "mobile",
  //   label: "Mobile",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "pinterest",
  //   label: "Pinterest",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "primary_phone",
  //   label: "Primary Phone",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "receive_text_message",
  //   label: "Receive Text Message",
  //   type: CustomFieldType.BOOLEAN,
  //   options: [
  //     { value: "true", label: "Yes" },
  //     { value: "false", label: "No" },
  //   ],
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "title",
  //   label: "Title",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "twitter",
  //   label: "Twitter",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_facebook",
  //   label: "User Facebook",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_instagram",
  //   label: "User Instagram",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_linkedin",
  //   label: "User Linkedin",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_pinterest",
  //   label: "User Pinterest",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "user_twitter",
  //   label: "User Twitter",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "work_experience",
  //   label: "Work Experience",
  //   type: CustomFieldType.TEXT,
  // },
  // {
  //   entity: EntitiesEnum.CUSTOMERS,
  //   key: "work_phone",
  //   label: "Work Phone",
  //   type: CustomFieldType.TEXT,
  // },
];

const PRODUCT_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "remote_id",
    label: "ID",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "name",
    label: "Name",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "wine_type",
    label: "Wine Type",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "varietal",
    label: "Varietal",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "vintage",
    label: "Vintage",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "country",
    label: "Country",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.PRODUCTS,
    key: "status",
    label: "Status",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
    ],
  },
];

const GROUP_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.GROUPS,
    key: "remote_id",
    label: "ID",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.GROUPS,
    key: "name",
    label: "Name",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    asyncOptions: async () => {
      const groups = await getGroups();
      return groups.map((group) => ({
        value: group.name,
        label: group.name,
      }));
    },
    options: [],
  },
  {
    entity: EntitiesEnum.GROUPS,
    key: "status",
    label: "Status",
    type: CustomFieldType.OPTIONS_MULTIPLE,
    options: [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
    ],
  },
];

const ORDER_CUSTOM_FIELDS: CustomField[] = [
  {
    entity: EntitiesEnum.ORDERS,
    key: "shipping_method_name",
    label: "Shipping Method",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: "total",
    label: "Total",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: "item_count",
    label: "Item count",
    type: CustomFieldType.NUMBER,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: "payment_status",
    label: "Payment Status",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: "state",
    label: "State",
    type: CustomFieldType.TEXT,
  },
  {
    entity: EntitiesEnum.ORDERS,
    key: "city",
    label: "City",
    type: CustomFieldType.TEXT,
  },
];

export enum RuleCondition {
  IS_EMPTY = "is_empty",
  MATCHES_EXACTLY = "matches_exactly",
  DOES_NOT_MATCH_EXACTLY = "does_not_match_exactly",
  IS_PROVIDED = "is_provided",
  IS_NOT_PROVIDED = "is_not_provided",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does_not_contain",
  STARTS_WITH = "starts_with",
  DOES_NOT_START_WITH = "does_not_start_with",
  ENDS_WITH = "ends_with",
  DOES_NOT_END_WITH = "does_not_end_with",
  GREATER_THAN = "greater_than",
  LESS_THAN = "less_than",
  GREATER_THAN_OR_EQUAL_TO = "greater_than_or_equal_to",
  LESS_THAN_OR_EQUAL_TO = "less_than_or_equal_to",
  IS_BETWEEN = "is_between",
  IS_BEFORE = "is_before",
  IS_AFTER = "is_after",
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does_not_equal",
  IS_ON_OR_BEFORE = "is_on_or_before",
  IS_ON_OR_AFTER = "is_on_or_after",
  IN = "in",
  NOT_IN = "not_in",
}

export const getConditionsByCustomFieldType = (
  customType: CustomField | undefined
): OptionType[] => {
  if (!customType) {
    return [];
  }

  switch (customType.type) {
    case CustomFieldType.TEXT:
      const array = [
        { value: RuleCondition.IS_EMPTY, label: "Is Empty" },
        { value: RuleCondition.MATCHES_EXACTLY, label: "Matches Exactly" },
        {
          value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
          label: "Does Not Match Exactly",
        },
        { value: RuleCondition.IS_PROVIDED, label: "Is Provided" },
        { value: RuleCondition.IS_NOT_PROVIDED, label: "Is Not Provided" },
        { value: RuleCondition.CONTAINS, label: "Contains" },
        { value: RuleCondition.DOES_NOT_CONTAIN, label: "Does Not Contain" },
        { value: RuleCondition.STARTS_WITH, label: "Starts With" },
        {
          value: RuleCondition.DOES_NOT_START_WITH,
          label: "Does Not Start With",
        },
        { value: RuleCondition.ENDS_WITH, label: "Ends With" },
        { value: RuleCondition.DOES_NOT_END_WITH, label: "Does Not End With" },
      ];

      return array;
    case CustomFieldType.NUMBER:
      return [
        { value: RuleCondition.IS_EMPTY, label: "Is Empty" },
        { value: RuleCondition.MATCHES_EXACTLY, label: "Matches Exactly" },
        {
          value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
          label: "Does Not Match Exactly",
        },
        { value: RuleCondition.IS_PROVIDED, label: "Is Provided" },
        { value: RuleCondition.IS_NOT_PROVIDED, label: "Is Not Provided" },
        { value: RuleCondition.GREATER_THAN, label: "Greater Than" },
        { value: RuleCondition.LESS_THAN, label: "Less Than" },
        {
          value: RuleCondition.GREATER_THAN_OR_EQUAL_TO,
          label: "Greater Than or Equal To",
        },
        {
          value: RuleCondition.LESS_THAN_OR_EQUAL_TO,
          label: "Less Than or Equal To",
        },
        { value: RuleCondition.IS_BETWEEN, label: "Is Between" },
      ];
    case CustomFieldType.DATE:
      return [
        { value: RuleCondition.IS_EMPTY, label: "Is Empty" },
        { value: RuleCondition.IS_BEFORE, label: "Is Before" },
        { value: RuleCondition.IS_AFTER, label: "Is After" },
        { value: RuleCondition.EQUALS, label: "Equals" },
        { value: RuleCondition.DOES_NOT_EQUAL, label: "Does Not Equal" },
        { value: RuleCondition.IS_PROVIDED, label: "Is Provided" },
        { value: RuleCondition.IS_NOT_PROVIDED, label: "Is Not Provided" },
        { value: RuleCondition.IS_ON_OR_BEFORE, label: "Is On or Before" },
        { value: RuleCondition.IS_ON_OR_AFTER, label: "Is On or After" },
        { value: RuleCondition.IS_BETWEEN, label: "Is Between" },
      ];
    case CustomFieldType.BOOLEAN:
    case CustomFieldType.OPTIONS_SINGLE:
      return [
        { value: RuleCondition.IS_EMPTY, label: "Is Empty" },
        { value: RuleCondition.MATCHES_EXACTLY, label: "Matches Exactly" },
        {
          value: RuleCondition.DOES_NOT_MATCH_EXACTLY,
          label: "Does Not Match Exactly",
        },
      ];
    case CustomFieldType.OPTIONS_MULTIPLE:
      return [
        { value: RuleCondition.IS_EMPTY, label: "Is Empty" },
        { value: RuleCondition.IN, label: "In" },
        { value: RuleCondition.NOT_IN, label: "Not In" },
      ];
  }
};

export function getCustomFieldsByEntity(entity: EntitiesEnum): CustomField[] {
  switch (entity) {
    case EntitiesEnum.CUSTOMERS:
      return CUSTOMER_CUSTOM_FIELDS;
    case EntitiesEnum.PRODUCTS:
      return PRODUCT_CUSTOM_FIELDS;
    case EntitiesEnum.GROUPS:
      return GROUP_CUSTOM_FIELDS;
    case EntitiesEnum.ORDERS:
      return ORDER_CUSTOM_FIELDS;
    default:
      return [];
  }
}

export function getMultiSelOptions(
  options: OptionType[],
  value: String
): OptionType[] | null {
  if (value.length > 0) {
    const array = value.split("|");
    var selOptions = [] as OptionType[];
    array.forEach((s) => {
      options.forEach((option) => {
        if (option.value.toLowerCase() === s.toLowerCase()) {
          selOptions.push(option);
        }
      });
    });
    return selOptions;
  }
  return null;
}
