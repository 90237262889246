import React, { useEffect, useState } from "react";
import { Flex, Box, Button, Image } from "@chakra-ui/react";
import RuleRow, { RuleRowType } from "./RuleRow";
import { FaPlusCircle } from "react-icons/fa";
import { EntitiesEnum } from "../../services/queryBuilder";

type RuleGroupProps = {
  ruleRows?: RuleRowType[];
  allowAndBtn?: boolean;
  entitiesToQueryBy: EntitiesEnum[];
  onUpdateResults: () => void;
  onAddRuleGroup: () => void;
  onRemoveRuleGroup: () => void;
  onRuleGroupUpdate: (ruleRows: RuleRowType[]) => void;
};

export const blankRow: RuleRowType = {
  field: null,
  condition: "",
  value1: "",
};

const RuleGroup = ({
  ruleRows,
  allowAndBtn,
  entitiesToQueryBy,
  onUpdateResults,
  onAddRuleGroup,
  onRemoveRuleGroup,
  onRuleGroupUpdate,
}: RuleGroupProps) => {
  const [rows, setRows] = useState<RuleRowType[]>(
    ruleRows?.length ? ruleRows : [blankRow]
  );

  const handleAddRow = () => {
    const newRows = [...rows, blankRow];
    setRows(newRows);
    onRuleGroupUpdate(newRows);
  };

  const handleAddGroup = () => {
    onAddRuleGroup();
  };

  const handleRemoveRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    if (newRows.length === 0) {
      onRemoveRuleGroup();
    } else {
      setRows(newRows);
      onRuleGroupUpdate(newRows);
    }
  };

  const handleRuleRowUpdate = (newRuleRow: RuleRowType, index: number) => {
    const newRows = [...rows];
    newRows[index] = newRuleRow;
    setRows(newRows);
    onRuleGroupUpdate(newRows);
  };

  useEffect(() => {
    if (ruleRows?.length === 0) {
      setRows([blankRow]);
    } else {
      setRows(ruleRows || [blankRow]);
    }
  }, [ruleRows]);

  return (
    <Box>
      <Box
        borderWidth={"1px"}
        borderRadius={"4px"}
        borderColor={"#DFE2E6"}
        mx={"24px"}
        px={"20px"}
        py={"2px"}
      >
        {rows.map((row, index) => (
          <Flex direction={"column"}>
            {index > 0 && (
              <Image
                width={"100%"}
                height={"1px"}
                backgroundImage={"linear-gradient(to right, #D4D4D4 0%, #D4D4D4 57%, transparent 43%)"}
                backgroundSize={"7px 1px"}
                backgroundRepeat={"repeat-x"}
              />
            )}
            <RuleRow
              entitiesToQueryBy={entitiesToQueryBy}
              key={index}
              row={row}
              onAddRuleRow={handleAddRow}
              onRemoveRuleRow={() => handleRemoveRow(index)}
              onRuleRowUpdate={(newRuleRow: RuleRowType) =>
                handleRuleRowUpdate(newRuleRow, index)
              }
              onUpdateResults={onUpdateResults}
              disableOr={rows.length > 1 ? index <= rows.length - 2 : false}
            />
          </Flex>
        ))}
      </Box>
      <Image
        width={"1px"}
        height={"20px"}
        backgroundColor={"#DFE2E6"}
        ml={"90px"}
      />
      <Flex
        ml={"52px"}>
        <Button
          px={"12px"}
          fontSize={"14px"}
          variant={"offset-rule"}
          leftIcon={<Image width={"16px"} src="/ic_add_gray.png"/>}
          disabled={allowAndBtn ? false : true}
          onClick={handleAddGroup}
        >
          And
        </Button>
      </Flex>
    </Box>
  );
};

export default RuleGroup;
