// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  styles: {
    global: {
      body: {
        bgColor: "gray.600",
      },
    },
  },
  colors: {
    orange: {
      100: "#c6513b",
      200: "#a84532",
    },
    blue: {
      100: "#2a2f56",
    },
    gray: {
      600: "#4f5268",
    },
  },
  components: {
    Select: {
      baseStyle: {},
      sizes: {},
      variants: {
        offset: {
          field: {
            background: "#fff",
            border: "1px solid red",
            borderColor: "blue.100",
            borderRadius: "4px",
          },
        },
      },
      defaultProps: {
        variant: "offset",
      },
    },
    Input: {
      baseStyle: {},
      sizes: {},
      variants: {
        offset: {
          field: {
            border: "1px solid",
            bgColor: "#fff",
            borderColor: "#2a2f56",
            borderRadius: "4px",
            marginBottom: "8px;",
          },
        },
      },
      defaultProps: {
        variant: "offset",
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {},
      // 2. We can add a new button size or extend existing
      sizes: {},
      // 3. We can add a new visual variant
      variants: {
        offset: {
          bg: "orange.100",
          color: "#fff",
          _hover: {
            bgColor: "orange.200",
            color: "#fff",
            _disabled: {
              bgColor: "orange.200",
            },
          },
        },
        "offset-export": {
          bg: "#fff",
          color: "#4F5268",
          border: "1px solid",
          borderColor: "#D5D7DB",
        },
        "offset-rule": {
          bg: "#FDF2EF",
          color: "#2A2F56",
          border: "1px solid",
          borderColor: "#D8A99E",
        },
        "offset-light": {
          bg: "#fff",
          color: "blue.100",
          border: "1px solid",
          borderColor: "blue.100",
          _hover: {
            color: "#fff",
            bgColor: "blue.100",
            _disabled: {
              color: "blue.100",
            },
          },
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        variant: "offset", // default is solid
        colorScheme: "orange.100", // default is gray
      },
    },
  },
});

export default theme;
