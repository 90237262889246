import { RuleRowType } from "../components/QueryBuilder/RuleRow";
import { EntitiesEnum } from "../services/queryBuilder";

export function formatCurrency(value: number | string | undefined): string {
  let number = Number(value);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
}

export function getTimeAgo(date: Date): string {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return `${seconds} seconds ago`;
  }
}

export function getUploadRuleGroups(rg: RuleRowType[][]): RuleRowType[][] {
  var groupArray: RuleRowType[][] = []
  rg.forEach((g) =>{
    var rowArray: RuleRowType[] = []
    g.forEach((e) =>{
      var value1 = e.value1;
      var value2 = e.value2;
      var field = e.field;
      if(e.field?.entity === EntitiesEnum.CUSTOMERS && e.field.key === "groups"){
        field = {
          ...e.field,
          entity: EntitiesEnum.GROUPS,
          key: "name",
          label: "Name",
        }
      }
      rowArray.push({
        id: e.id,
        field: field,
        condition: e.condition,
        value1: value1,
        value2: value2
      });
    })
    groupArray.push(rowArray);
  })

  return groupArray;
}

export function getPhoneFormat(str: string){
  var phoneStr = str
  if(phoneStr.length > 0){
    phoneStr = phoneStr.replaceAll("-", "")
    var tempStr = ""
    Object.values(phoneStr).map((e) =>{
      if(!isNaN(parseInt(e))){
        if(tempStr.length === 3 || tempStr.length === 7){
          tempStr += "-" + e
        }else if(tempStr.length < 12){
          tempStr += e
        }
      }
    })
    phoneStr = tempStr
  }
  return phoneStr
}

export function getNumFormat(str: string){
  var numStr = ""
  if(str.length > 0){
    Object.values(str).map((e) =>{
      if(!isNaN(parseInt(e))){
        numStr += e
      }
    })
  }
  return numStr
}