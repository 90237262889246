import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  useToast,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const toast = useToast();
  const history = useHistory();

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if(!password){
      toast({
        title: "Please enter new password",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
      return
    }
    if(!confirmPassword){
      toast({
        title: "Please re-enter new password",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
      return
    }
    if(password != confirmPassword){
      toast({
        title: "Please confirm your new password",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
      return
    }
    try {
      setIsLoading(true);
      history.push(`/login`);
    } catch (error) {
      toast({
        title: "Error reset",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      px={"40px"}
      flexDirection={"column"}>
      <Flex
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        h="88vh"
      >
        <Heading as="h1" fontSize={"61px"} fontFamily={"Beatrice-Regular"} color={"white"} mb={6}>
          Reset password
        </Heading>
        <Text 
          mb={8} 
          fontSize={"14px"} 
          color={"white"}
          textAlign={"center"}>
            Remember, this if for all your Offset accounts.
        </Text>
        <Flex mt={4} flexDirection="column" w="400px">
          <Text 
            mb={1} 
            fontSize={"14px"} 
            color={"white"}>
              New password
          </Text>
          <Flex justifyContent={"end"}>
            <Input
              autoFocus
              placeholder="Enter new password"
              type={showPassword ? "text" : "password"}
              variant="offset"
              mb={5}
              fontSize={"14px"} 
              height={"40px"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              position={"absolute"}
              mr={"12px"}
              mt={"8px"}
              cursor={"pointer"}
              onClick={(e) => setShowPassword(!showPassword)} >
              {showPassword?(
                <Image 
                  width={"24px"}
                  src="/eye_off.png" />
              ):(
                <Image 
                  width={"24px"}
                  src="/eye.png" />
              )}
            </Box>
          </Flex>
          <Text 
            mb={1} 
            fontSize={"14px"} 
            color={"white"}>
              Confirm new password
          </Text>
          <Flex justifyContent={"end"}>
            <Input
              placeholder="Re-enter new password"
              type={showConfirmPassword ? "text" : "password"}
              variant="offset"
              mb={6}
              fontSize={"14px"}
              height={"40px"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            />
            <Box
              position={"absolute"}
              mr={"12px"}
              mt={"8px"}
              cursor={"pointer"}
              onClick={(e) => setShowConfirmPassword(!showConfirmPassword)} >
              {showConfirmPassword?(
                <Image 
                  width={"24px"}
                  src="/eye_off.png" />
              ):(
                <Image 
                  width={"24px"}
                  src="/eye.png" />
              )}
            </Box>
          </Flex>
          <Button
            isLoading={isLoading}
            colorScheme="teal"
            mt={2}
            mb={3}
            fontSize={"16px"} 
            height={"48px"}
            onClick={handleSubmit}
          >
            Reset Password
          </Button>
        </Flex>
      </Flex>

      <Flex 
        flexDirection={"column"}
        alignItems="start"
      >
        <Image 
          src="/offset-logo-white.png" 
          w={"80px"} />
        <Text 
          mt={4} 
          fontSize={"10px"} 
          color={"white"}>
            Terms & Privacy
        </Text>
        <Text 
          mt={1} 
          fontSize={"10px"} 
          color={"white"}>
            @2021 Offset Partners
        </Text>
      </Flex>
    </Flex>
  );
};

export default ResetPassword;